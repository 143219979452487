import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import instance from "./auth/useJwt";
import RouterComp from "./routes/all_routes";
import { deleteAccessToken } from "./auth/utils";
import { handleLogin, handleLogout } from "./redux/authentication";
import { Box, CircularProgress, Typography } from "@mui/material";

function App() {
  const auth = useSelector(state => state.authentication)

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)

  const fetchUser = async () => {
    try {
      instance.get(`/user`).then(res => {
        var user_data = res.data
        console.log(user_data)
        dispatch(handleLogin(user_data))

        setIsLoading(false)
      }).catch(err => {

        deleteAccessToken()
        dispatch(handleLogout())
        setIsLoading(false)
      })

    } catch (error) {
      deleteAccessToken()
      dispatch(handleLogout())
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchUser()
  }, [])
  console.log(auth)

  if (isLoading) {
    return <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100vh', alignContent: 'center', alignItems: 'center' }}>
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 1 }}>Loading...</Typography>
      </Box>
    </Box>
  }


  return (
    <RouterComp />
  );
}

export default App;
