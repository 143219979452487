import React, { useState, useEffect } from "react";
import DashboardLayout from "../../components/layouts/Dashboard";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SGIComparison from "./components/SGIComparison";
import SGIComparisonTable from "./SGIComparisonTable";
import { fetchMarketData } from "../../redux/market_data";
import { useDispatch, useSelector } from "react-redux";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  console.log(value, index)
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};


export default function MarketData(props) {
  const [value, setValue] = useState('graph');
  const {data, grade_salaries, loading, error, apiFilters } = useSelector(state => state.market_data)
  const dispatch = useDispatch()
  const handleChange = (event, newValue) => {
    console.log(newValue)
    setValue(newValue)
  };



  useEffect(() => {
    dispatch(fetchMarketData(apiFilters))
  }, [apiFilters])

  const tabs_data = [
    { id: 'graph', label: 'Graph View' },
    { id: 'detailed-view', label: 'Detailed View' }
  ]

  return (
    <DashboardLayout>
      {/* {loading ?
        <PageLoader />
        : */}
        {
        error ?
          <div className="text-center text-red-500 text-xl mt-5">{error}</div>
          :
          <Box sx={{ width: '100%' }} className="px-4">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                {tabs_data.map((item, index) => (
                  <Tab sx={{ fontSize: '14px', textTransform: 'capitalize' }} key={`tb-${index}`} label={item.label} value={item.id} />
                ))
                }
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={`graph`}>

              <SGIComparison data={grade_salaries} />

            </CustomTabPanel>
            <CustomTabPanel value={value} index={'detailed-view'}>
              <SGIComparisonTable data={data} />
            </CustomTabPanel>
          </Box>

      }
    </DashboardLayout>
  );
}

