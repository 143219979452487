import { Box, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { DataGrid } from '@mui/x-data-grid'; import { styled } from '@mui/material';

const StyledDataGrid = styled(DataGrid)({
    // set width to 100%
    '& .MuiDataGrid-root': {
        width: '100%',
    },
    '& .MuiDataGrid-cell': {
        borderRight: '1px solid #ddd', // Add vertical borders to cells
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#F9FAFB', // Set table heading row background to #eee
    },
});


export default function StatusHistoryCard(props) {
    const { data } = props
    if (!data) {
        return null
    }

    // Add id from index
    const final_data = data.map((item, index) => {
        return {
            ...item,
            id: index + 1
        }
    })

    const COLUMNS = [
        { field: 'id', headerName: '', width: 50 },
        { field: 'updated_at', headerName: 'Updated At', width: 150 },
        {
            field: 'updated_by', headerName: 'Updated By', width: 150, renderCell(params) {
                return ( // If is null or undefined, return "System"
                    <span>{params.value ? params.value : "System"}</span>
                )
            }
        },
        { field: 'approved', headerName: 'Approved', width: 150 },
        { field: 'can_edit', headerName: 'Can Edit', width: 150 },
        { field: 'reason', headerName: 'Reason', flex: 1 },
    ]



    return (
        <Box sx={{ mt: 2 }}>
            <Card variant="outlined" sx={{ pb: 0 }}>
                <Box sx={{
                    backgroundColor: '#F9FAFB',
                    borderBottom: '1px solid #ddd',
                    fontSize: '1.3rem', px: 2, py: 1.1
                }}>History</Box>
                <CardContent sx={{ p: 0, padding: "0px !important" }}>
                    <StyledDataGrid
                        rows={final_data}
                        // Make it compact
                        density="compact"
                        columns={COLUMNS}
                        autoHeight
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick

                        sx={{ '--DataGrid-overlayHeight': '100px' }}
                    />


                </CardContent>
            </Card>

        </Box>
    )
}