import { createSlice } from '@reduxjs/toolkit'
import { deleteAccessToken, deleteRefreshToken } from '../auth/utils'

// ** UseJWT import to get config
//import useJwt from '../core/auth/jwt/useJwt'
const config =  {

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}

const initialUser = () => {
  const item = localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  //if (item)
  //return item !== "undefined" ? JSON.parse(item) : {}

  return {}
}

// "id" : str(user.get("_id")),
//                 "name" : user.get("full_name"),
//                 "username" : user.get("username"),
//                 "email" : user.get("useremail"),
//                 "created_at" : user.get("created_at").strftime("%Y-%m-%d %H:%M:%S"),
//                 "role" : user.get("role", "user"),
//                 "onboarding_approved" : user.get("onboarding_approved", False),
//                 "country": country,
//                 "currency": currency,
//                 "company_name": company_name,
//                 "access" : company_access

export const authSlice = createSlice({
  name: 'authentication',
  initialState: initialUser(),
  reducers: {
    handleLogin: (state, action) => {
      //state = action.payload
      state.id = action.payload.id
      state.name = action.payload.name
      state.username = action.payload.username
      state.email = action.payload.email
      state.created_at = action.payload.created_at
      state.role = action.payload.role
      state.onboarding_approved = action.payload.onboarding_approved
      state.country = action.payload.country
      state.currency = action.payload.currency
      state.company_name = action.payload.company_name
      state.access = action.payload.access
      
      //localStorage.setItem('userData', JSON.stringify(action.payload))
    }, 
    changeUserRole:(state, action) => {
      state.role = action.payload
    }, 
    handleLogout: state => {
      state = {}

      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      deleteAccessToken()
      deleteRefreshToken()
    }
  }
}) 

export const { handleLogin, handleLogout, changeUserRole } = authSlice.actions

export default authSlice.reducer