import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../auth/useJwt';

// Async action
// export const fetchMarketData = createAsyncThunk(
//   'market_data/fetchMarketData',
//   async () => {
//     const response = await instance.get("/market-data");
//     return response.data;
//   }
// );

function sortArrayByNumber(strings) {
    // Ignore any value which is null or undefined
    strings = strings.filter(Boolean);
    return strings.sort((a, b) => {
        // Extract the numeric part from each string
        const numberA = parseInt(a.match(/\d+/), 10);
        const numberB = parseInt(b.match(/\d+/), 10);

        // Compare the numeric parts
        return numberA - numberB;
    });
}


export const fetchMarketData = createAsyncThunk(
    'market_data/fetchMarketData',
    async (params, { rejectWithValue }) => {
        try {
            const response = await instance.get(`/market-data?compensation_type=${params.compensation_type}&frequency=${params.frequency}`);
            return response.data;
        } catch (err) {
            const errorMsg = err?.response?.data ? err?.response?.data : "Error fetching data";
            return rejectWithValue(errorMsg);
        }
    }
);


export const marketDataSlice = createSlice({
    name: 'market_data',
    initialState: {
        data: [],
        allData: [],
        grade_salaries: [],
        loading: true,
        error: null,
        company_name: null,
        apiFilters: {
            compensation_type: "annual_basic_salary",
            frequency: "annual",
            loading: false
        },
        filters: {
            role: null,
            grade: null,
            location: null,
            industry: null,
            jobFamily: null,
            sector: null,
        },
        uniqueRoles: [],
        uniqueGrades: [],
        uniqueIndustries: [],
        uniqueJobFamilies: [],
        uniqueLocations: [],
        uniqueSectors: [],
    },
    reducers: {
        setApiFilters: (state, action) => {
            state.apiFilters = action.payload;
            state.loading = true;
        },
        setMarketFilters: (state, action) => {
            state.filters = action.payload;
            
            const { role, grade, industry, 
                jobFamily, location, sector
            } = state.filters;
            console.log(role, grade)
            // Filter based on role and grade
            if (role || grade || industry || jobFamily || location || sector) {
                state.data = state.allData.filter(item => {
                    let roleMatch = true;
                    let gradeMatch = true;
                    let industryMatch = true;
                    let jobFamilyMatch = true;
                    let locationMatch = true;
                    let sectorMatch = true;

                    if (role) {
                        roleMatch = item.role === role;
                    }

                    if (grade) {
                        gradeMatch = item.grade === grade;
                    }

                    if (industry) {
                        industryMatch = item.industry === industry;
                    }

                    if (jobFamily) {
                        jobFamilyMatch = item.job_families.includes(jobFamily);
                    }

                    if (location) {
                        locationMatch = item.location.includes(location);
                    }

                    if (sector) {
                        sectorMatch = item.sector === sector;
                    }



                    return roleMatch && gradeMatch && industryMatch && jobFamilyMatch && locationMatch && sectorMatch;
                });
            } else {
                // If both filters are null, reset data to allData
                state.data = state.allData;
            }

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMarketData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMarketData.fulfilled, (state, action) => {
                state.data = action.payload.table_results;
                state.allData = action.payload.table_results;
                state.grade_salaries = action.payload.grade_salaries;
                state.company_name = action.payload.company_name;

                const roles = action.payload.table_results.map(item => item.role);
                state.uniqueRoles = [...new Set(roles)];

                // Extract unique grades
                const grades = action.payload.table_results.map(item => item.grade);
                state.uniqueGrades = sortArrayByNumber([...new Set(grades)]);

                // Extract unique industries
                const industries = action.payload.table_results.map(item => item.industry);
                state.uniqueIndustries = [...new Set(industries)];

                // each table row also have column `job_families` which is an array, get uniqe job families
                const job_families = action.payload.table_results.map(item => item.job_families).flat();
                state.uniqueJobFamilies = [...new Set(job_families)];

                // Extract unique locations, which is an array
                const locations = action.payload.table_results.map(item => item.location).flat();
                state.uniqueLocations = [...new Set(locations)];

                // Extract unique sectors
                const sectors = action.payload.table_results.map(item => item.sector);
                state.uniqueSectors = [...new Set(sectors)];

                state.loading = false;
                state.apiFilters.loading = false;
            })
            .addCase(fetchMarketData.rejected, (state, action) => {
                state.loading = false;
                // Here, action.payload will contain the custom error message
                state.error = action.payload;
            });
    }
});

export const { setMarketFilters, setApiFilters } = marketDataSlice.actions;

export default marketDataSlice.reducer;
